@media only screen and (max-width: 500px) {
  .amplify {
    position: "relative";
    margin: 0;
    transform: translateY(10%);
  }
}

@media only screen and (min-width: 500px) {
  .amplify {
    margin: 0;
    position: absolute;
    top: 40%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
